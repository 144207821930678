import { AnyAction, combineReducers } from "redux";
import { combineEpics, Epic } from "redux-observable";
import { catchError } from "rxjs";
import { coreApi } from "./api";
import { counterSlice, magicEpic } from "./counter";
import { credentialSlice } from "./credential";

const rootReducer = combineReducers({
  counter: counterSlice.reducer,
  credential: credentialSlice.reducer,
  [coreApi.reducerPath]: coreApi.reducer
})

export const rootEpic: Epic<AnyAction, AnyAction, RootState> = (
  action$,
  store$,
  dependencies
) => combineEpics(
  magicEpic
)(action$, store$, dependencies).pipe(
  catchError((error, source) => {
    console.error(error)
    console.log('Epic error occured')
    return source
  })
)

export default rootReducer
export type RootState = ReturnType<typeof rootReducer>

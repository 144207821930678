import { MoonIcon, SunIcon } from '@chakra-ui/icons'
import { IconButton, useColorMode } from '@chakra-ui/react'
import React from 'react'

export const ColorModeToggler: React.FC = () => {
  const { colorMode, toggleColorMode } = useColorMode()

  return (
    <IconButton
      variant="solid"
      aria-label='Toggle Color Mode'
      size='sm'
      icon={colorMode === 'light' ? <SunIcon /> : <MoonIcon />}
      onClick={toggleColorMode}
    />
  )
}
import {
  Alert,
  AlertIcon,
  chakra,
  Container,
  VStack
} from '@chakra-ui/react'
import React, { useEffect } from 'react'
import { BasicInfo } from '../components/BasicInfo'
import { Navbar } from '../components/Navbar'
import { useGetCurrentUserQuery } from '../redux/reducer/api'
import { useSelector } from 'react-redux'
import { RootState } from '../redux/reducer'
import { useNavigate } from 'react-router-dom'
import { IsCode } from '../lib/helper'

export const Home: React.FC = () => {
  const { refetch } = useGetCurrentUserQuery()

  const credential = useSelector((state: RootState) => state.credential)
  const { data: user, isError } = useGetCurrentUserQuery()

  const navigate = useNavigate()

  useEffect(() => {
    refetch()
  }, [])

  useEffect(() => {
    if(user !== undefined) return
    if(credential.user === undefined) return
    if(IsCode(credential.user.profileObj.email.split('@')[0])) {
      navigate('/register')
    }
    else {
      navigate('/teacherregister')
    }
  }, [credential, user])

  return (
    <div>
      <Navbar active="home" />
      <Container maxW='container.lg' px={4} py={4}>
        <VStack spacing={7} align="normal">
          {
            credential.user !== undefined ?
            <>
              <VStack spacing={3} align="normal">
                <chakra.h2 fontWeight="bold" fontSize="lg">
                  현재 상태
                </chakra.h2>
                <BasicInfo />
              </VStack>
            </> : 
            <>
              <Alert status="warning">
                <AlertIcon/>
                본교 학생/교사임을 인증하기 위해 먼저 구글 로그인을 해주세요. 
              </Alert>
            </>
          }
        </VStack>
      </Container>
    </div>
  )
}
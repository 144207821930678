import { Badge, HStack, Select, Table, Tbody, Td, Th, Thead, Tr, useBreakpointValue, VStack } from "@chakra-ui/react";
import React, { useState } from "react";
import { composeAltCode, stringComparator } from "../lib/helper";
import { Student, User } from "../lib/types";

type Props = {
  students: (User & Student)[],
  select?: {
    selected: Set<User & Student>,
    setSelected: React.Dispatch<React.SetStateAction<Set<User & Student>>>
  }
}

export const StudentViewByRoles: React.FC<Props> = (props: Props) => {
  const [sorter, setSorter] = useState<string>("")
  const size = useBreakpointValue({ base: 'sm', md: 'md' })
  const { students: studentsRaw } = props

  const students = [...studentsRaw]
  students.sort((l, r) => {
    if (sorter === "" || sorter === "altcode") return stringComparator(composeAltCode(l), composeAltCode(r))
    return stringComparator(l.name, r.name)
  })

  return (
    <VStack spacing={3}>
      <Select
        variant="flushed" size={size}
        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
          setSorter(e.target.value)
        }}
      >
        <option value="altcode" selected>학번순</option>
        <option value="name">이름순</option>
      </Select>
      <Table variant="simple" borderWidth="1px" size={size}>
        <Thead>
          <Tr>
            <Th>학번</Th>
            <Th>이름</Th>
            <Th>역할</Th>
          </Tr>
        </Thead>
        <Tbody>
          {students.map(student => (
            <Tr key={student.id}>
              <Td>{composeAltCode(student)}</Td>
              <Td>{student.name}</Td>
              <Td>
                <HStack>
                  {student.roles.map((role, index) => <Badge variant="solid" key={index}>{role}</Badge>)}
                </HStack>
              </Td>

            </Tr>
          ))}
        </Tbody>
      </Table>
    </VStack>
  )
}
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState } from "../redux/reducer";
import { useGetCurrentUserQuery } from "../redux/reducer/api";

export function useCheckAdmin(): boolean {
    const { data: user } = useGetCurrentUserQuery()
    if (user === undefined) return false
    return user.roles.includes('admin')
}

export function useCheckLogined(): boolean {
    const credential = useSelector((state: RootState) => state.credential)
    return credential.user !== undefined
}

export function useNavigateIfNotLogined(): void {
    const credential = useSelector((state: RootState) => state.credential)
    const navigate = useNavigate()
    useEffect(() => {
        if (credential.user === undefined) navigate('/')
    }, [credential])
}

export function useNavigateIfNotAdmin(): void {
    const { data: user } = useGetCurrentUserQuery()
    const navigate = useNavigate()
    if (user === undefined) {
        navigate('/')
        return
    }
    if (!user.roles.includes('admin')) navigate('/')
}
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AnyAction } from 'redux'
import { Epic } from 'redux-observable'
import { delay, filter, mapTo } from 'rxjs'
import { RootState } from '.'

export interface CounterState {
  value: number
}

const initialState: CounterState = {
  value: 0,
}

export const counterSlice = createSlice({
  name: 'counter',
  initialState,
  reducers: {
    increment: (state) => {
      state.value += 1
    },
    decrement: (state) => {
      state.value -= 1
    },
    incrementByAmount: (state, action: PayloadAction<number>) => {
      state.value += action.payload
    },
    magic: (state) => {
      state.value *= 2
    }
  },
})

export const { increment, decrement, incrementByAmount, magic } = counterSlice.actions

export const magicEpic: Epic<AnyAction, AnyAction, RootState> = (
  action$
) => action$.pipe(
  filter(counterSlice.actions.magic.match),
  delay(1000),
  mapTo(incrementByAmount(10))
)

export default counterSlice.reducer

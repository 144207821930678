import {
  Container,
  chakra,
  Input,
  VStack,
  HStack,
  Button
} from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Navbar } from '../components/Navbar'
import { useNavigateIfNotAdmin, useNavigateIfNotLogined } from '../lib/helprHooks'
import { RootState } from '../redux/reducer'
import { usePutRoleMutation } from '../redux/reducer/api'

export const MakeRoles: React.FC = () => {
  const [roleName, setRoleName] = useState<string>("")
  const [roleId, setRoleId] = useState<string>("")
  const [putRole] = usePutRoleMutation()
  useNavigateIfNotLogined()
  useNavigateIfNotAdmin()

  return (
    <div>
      <Navbar active="makeroles" />
      <Container maxW='container.lg' px={4} py={4}>
        <VStack spacing={7} align="normal">
          <VStack spacing={3} align="normal">
            <chakra.h2 fontWeight="bold" fontSize="lg">
              이름
            </chakra.h2>
            <Input value={roleName} onChange={e => setRoleName(e.target.value)} />
          </VStack>
          <VStack spacing={3} align="normal">
            <chakra.h2 fontWeight="bold" fontSize="lg">
              ID
            </chakra.h2>
            <Input value={roleId} onChange={e => setRoleId(e.target.value)} />
          </VStack>
          <HStack spacing={3} align="normal">
            <Button
              onClick = { () => {
                setRoleName('')
                setRoleId('')
              }}
            >
              취소
            </Button>
            <Button colorScheme={"purple"}
              onClick = {async () => {
                await putRole({id: roleId, name: roleName})
                setRoleName('')
                setRoleId('')
              }}
            >
              추가
            </Button>
          </HStack>
        </VStack>
      </Container>
    </div>
  )
}
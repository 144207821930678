import { getDefaultMiddleware } from "@reduxjs/toolkit"
import { AnyAction, applyMiddleware, compose, createStore, Middleware, Store, StoreEnhancer } from "redux"
import { composeWithDevTools } from "redux-devtools-extension"
import { createEpicMiddleware } from "redux-observable"
import { persistReducer, persistStore } from 'redux-persist'
import { Persistor } from "redux-persist/es/types"
import storage from 'redux-persist/lib/storage'
import rootReducer, { rootEpic, RootState } from "./reducer"
import { coreApi } from "./reducer/api"

export interface PersistantStore extends Store<RootState> {
  __persistor?: Persistor
}

const epicMiddleware = createEpicMiddleware<AnyAction, AnyAction, RootState>();

const configureStore = (): PersistantStore => {
  const middlewares: Middleware[] = [...getDefaultMiddleware({
    serializableCheck: false
  }), epicMiddleware, coreApi.middleware];
  const enhancer: StoreEnhancer = process.env.NODE_ENV === 'production' ?
    compose(applyMiddleware(...middlewares)) :
    composeWithDevTools({ trace: true })(applyMiddleware(...middlewares));

  const persistedReducer = persistReducer({
    key: 'root',
    storage: storage,
    whitelist: ['counter', 'credential']
  }, rootReducer)

  const store = createStore(persistedReducer, {}, enhancer);
  epicMiddleware.run(rootEpic)

  return { ...store, __persistor: persistStore(store) }
}

export default configureStore
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { stringify } from 'qs'
import { RootState } from '.'
import { isStudent } from '../../lib/helper'
import { BaseUser, Student, Teacher, User, UserApplied } from '../../lib/types'

// eslint-disable-next-line
const serialize = (obj: any) => stringify(obj, { arrayFormat: "repeat" })

export const coreApi = createApi({
  reducerPath: 'coreApi',
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://api.sshs-pebble.dev/core/',
    prepareHeaders: (headers, { getState }) => {
      const access_token = (getState() as RootState).credential.access_token
      headers.set('Authorization', `Bearer ${access_token}`)
      headers.set('Access-Control-Allow-Origin', '*')
      return headers
    }
  }),
  endpoints: (builder) => ({
    getCurrentUser: builder.query<User & UserApplied, void>({
      query: () => `user`,
      transformResponse: (response: { count: number, users: (User & UserApplied)[] }) => {
        return response.users[0]
      }
    }),
    getAllStudents: builder.query<(User & Student)[], void>({
      query: () => `user?${serialize({ grade: 0, class_: 0, number_: 0 })}`,
      transformResponse: (response: { count: number, users: (User)[] }) => {
        return response.users.filter(isStudent)
      }
    }),
    postRegisterStudent: builder.mutation<void, Omit<BaseUser&Student, "id">>({
      query: (body) => ({
        url: `p/register`,
        method: 'POST',
        body: body
      })
    }),
    postRegisterTeacher: builder.mutation<void, Omit<BaseUser&Teacher, ("id"| "code" | "phone" | "roles")>>({
      query: (body) => ({
        url: `p/register`,
        method: 'POST',
        body: body
      })
    }),
    postRole: builder.mutation<void, { id:string, add: string[], del: string[] }>({
      query: (body) => ({
        url: `role`,
        method: 'POST',
        body
      })
    }),
    putRole: builder.mutation<void, { id:string, name: string}>({
      query: (body) => ({
        url: `role`,
        method: 'PUT',
        body
      })
    }),
  })
})

export const {
  useGetCurrentUserQuery,
  useGetAllStudentsQuery,
  usePostRegisterStudentMutation,
  usePostRegisterTeacherMutation,
  usePostRoleMutation,
  usePutRoleMutation
} = coreApi

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../redux/reducer";
import { useGetCurrentUserQuery } from "../redux/reducer/api";
import { reloadCredential } from "../redux/reducer/credential";

type Props = {
  children: React.ReactNode
}

export const AuthProvider: React.FC<Props> = (props: Props) => {
  const dispatch = useDispatch()
  const { user } = useSelector((state: RootState) => state.credential)
  const { refetch } = useGetCurrentUserQuery()

  useEffect(() => {
    (async () => {
      if (user) {
        const refresh = async () => {
          // eslint-disable-next-line
          const windowany = window as any
          if (windowany.gapi && user.tokenObj.expires_at - (new Date()).getTime() < 15 * 60 * 1000) {
            const authresponse = await windowany.gapi.auth2.getAuthInstance().currentUser.get().reloadAuthResponse()

            dispatch(reloadCredential(authresponse))
            refetch()
          }

          setTimeout(refresh, 5 * 60 * 1000)
        }

        await refresh()
      }
    })();
  })

  return (
    <React.Fragment>
      {props.children}
    </React.Fragment>
  )
}
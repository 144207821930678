import { Box, Text, useColorModeValue } from "@chakra-ui/react";
import {
  AutoComplete,
  AutoCompleteInput,
  AutoCompleteItem,
  AutoCompleteList, AutoCompleteTag
} from "@choc-ui/chakra-autocomplete";
import React, { useImperativeHandle } from "react";
import { Student, User } from "../lib/types";

type Props = {
  students: (User & Student)[]
  selected: string[]
  setSelected: React.Dispatch<React.SetStateAction<string[]>>
}

type Handle = {
  reset: () => void
}

export const StudentDropdown = React.forwardRef<Handle, Props>(function StudentDropdownInner(props: Props, forwardedRef) {
  const { selected, setSelected, students } = props
  const borderColor = useColorModeValue('purple.600', 'purple.200')

  useImperativeHandle(forwardedRef, () => ({
    reset: () => {
      setSelected([])
    }
  }))

  return (
    <AutoComplete
      openOnFocus
      rollNavigation
      multiple
      values={selected}
      onChange={setSelected}
    >
      <AutoCompleteInput variant="filled" sx={{ ":focus-within": { borderColor } }}>
        {({ tags }) =>
          tags.map((tag, tid) => (
            <AutoCompleteTag
              key={tid}
              label={tag.label}
              onRemove={tag.onRemove}
              mx={1}
            />
          ))
        }
      </AutoCompleteInput>
      <AutoCompleteList>
        {(students || []).map((student, idx) => (
          <AutoCompleteItem
            key={`${idx}`}
            value={student.id}
            label={student.name}
          >
            <Box>
              <Text fontWeight="bold">{student.name}</Text>
              <Text fontSize="xs">{student.grade}학년 {student.class_}반 {student.number_}번</Text>
            </Box>
          </AutoCompleteItem>
        ))}
      </AutoCompleteList>
    </AutoComplete>
  );
})

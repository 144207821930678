import {
  Box,
  Button,
  chakra, CloseButton, Container, Flex,
  HStack,
  IconButton,
  Slide,
  useColorModeValue,
  useDisclosure,
  VStack
} from "@chakra-ui/react";
import React from "react";
import { AiOutlineMenu } from 'react-icons/ai'
import { Link } from "react-router-dom";
import { ColorModeToggler } from "./ColorModeToggler";
import { useGetCurrentUserQuery } from "../redux/reducer/api"
import { GoogleButton } from "./GoogleButton";
import { RootState } from "../redux/reducer"
import { useSelector } from 'react-redux'

type Props = {
  active: "home" | "register" | "teacherregister" | "manageroles" | "makeroles"
}

export const Navbar: React.FC<Props> = (props: Props) => {
  const bg = useColorModeValue("white", "gray.800")
  const { isOpen, onToggle } = useDisclosure()
  const { data: user, isError } = useGetCurrentUserQuery()

  const credential = useSelector((state: RootState) => state.credential)

  return (
    <chakra.header
      bg={bg}
      w="full"
      shadow='md'
    >
      <Container maxW='container.lg' px={4} py={4}>
        <Flex alignItems="center" justifyContent="space-between" mx="auto">
          <Flex>
            <chakra.h1
              fontSize="xl" fontWeight="bold"
            >
              Pebble
            </chakra.h1>
          </Flex>
          <HStack display="flex" alignItems="center" spacing={3}>
            <HStack
              spacing={1}
              mr={1}
              color="brand.500"
              display={{ base: "none", md: "inline-flex" }}
            >
              {(credential.user !== undefined) &&
                <Link to='/'>
                  <Button variant="ghost" colorScheme={props.active === "home" ? "purple" : undefined}>
                    홈
                  </Button>
                </Link>
              }
              {(credential.user !== undefined) && (user === undefined) &&
                <>
                  <Link to='/register'>
                    <Button variant="ghost" colorScheme={props.active === "register" ? "purple" : undefined}>
                      학생 가입 신청
                    </Button>
                  </Link>
                  <Link to='/teacherregister'>
                    <Button variant="ghost" colorScheme={props.active === "teacherregister" ? "purple" : undefined}>
                      교사 가입 신청
                    </Button>
                  </Link>
                </>
              }
              {
                (user && user.roles.includes('admin')) && !isError &&
                <>
                  <Link to="/manageroles">
                    <Button variant="ghost" colorScheme={props.active === "manageroles" ? "purple" : undefined}>
                      역할 관리
                    </Button>
                  </Link>
                  <Link to="/makeroles">
                    <Button variant="ghost" colorScheme={props.active === "makeroles" ? "purple" : undefined}>
                      역할 추가
                    </Button>
                  </Link>
                </>
              }
            </HStack>
            <ColorModeToggler />
            <GoogleButton />
            <Box display={{ base: "inline-flex", md: "none" }} zIndex="1000">
              <IconButton
                display={{ base: "flex", md: "none" }}
                aria-label="Open menu"
                fontSize="20px"
                color={useColorModeValue("gray.800", "inherit")}
                variant="ghost"
                icon={<AiOutlineMenu />}
                onClick={onToggle}
              />

              <Slide direction="top" in={isOpen}>
                <VStack
                  flexDirection="column"
                  p={2} pb={4} m={2}
                  bg={bg}
                  spacing={3}
                  rounded="sm"
                  shadow="sm"
                >
                  <CloseButton
                    aria-label="Close menu"
                    onClick={onToggle}
                  />
                  {(credential.user !== undefined) &&
                    <Link to='/'>
                      <Button w="full" variant="ghost" colorScheme={props.active === "home" ? "purple" : undefined}>
                        홈
                      </Button>
                    </Link>
                  }
                  {
                    (credential.user !== undefined) && (user === undefined) &&
                    <>
                      <Link to='/register'>
                        <Button w="full" variant="ghost" colorScheme={props.active === "register" ? "purple" : undefined}>
                          학생 가입 신청
                        </Button>
                      </Link>
                      <Link to='/teacherregister'>
                        <Button w="full" variant="ghost" colorScheme={props.active === "teacherregister" ? "purple" : undefined}>
                          교사 가입 신청
                        </Button>
                      </Link>
                    </>
                  }
                  {
                    (user && user.roles.includes('admin')) && !isError &&
                    <>
                      <Link to="/manageroles">
                        <Button variant="ghost" colorScheme={props.active === "manageroles" ? "purple" : undefined}>
                          역할 관리
                        </Button>
                      </Link>
                      <Link to="/makeroles">
                        <Button variant="ghost" colorScheme={props.active === "makeroles" ? "purple" : undefined}>
                          역할 추가
                        </Button>
                      </Link>
                    </>
                  }
                </VStack>
              </Slide>
            </Box>
          </HStack>
        </Flex>
      </Container>
    </chakra.header>
  );
}

import { ChakraProvider } from '@chakra-ui/react';
import React from 'react';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { AuthProvider } from './components/AuthProvider';
import { Home } from './pages/Home';
import { MakeRoles } from './pages/MakeRoles';
import { ManageRoles } from './pages/ManageRoles';
import { Register } from './pages/Register';
import { RegisterSuccess } from './pages/RegisterSuccess';
import { TeacherRegister } from './pages/TeacherRegister';
import configureStore from './redux/store';


const store = configureStore()

ReactDOM.render(
  <Provider store={store}>
    <PersistGate persistor={store.__persistor!}>
      <ChakraProvider>
        <AuthProvider>
          <BrowserRouter>
            <Routes>
              <Route path='/' element={<Home />} />
              <Route path='/success' element={<RegisterSuccess />} />
              <Route path='/makeroles' element={<MakeRoles />} />
              <Route path='/register' element={<Register />} />
              <Route path='/teacherregister' element={<TeacherRegister />} />
              <Route path='/manageroles' element={<ManageRoles />} />
            </Routes>
          </BrowserRouter>
        </AuthProvider>
      </ChakraProvider>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);

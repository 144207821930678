import { Button, chakra, Container, Flex, FormControl, HStack, Input, Skeleton, Spacer, VStack } from '@chakra-ui/react'
import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Navbar } from '../components/Navbar'
import { StudentDropdown } from '../components/StudentDropdown'
import { StudentViewByRoles } from '../components/StudentViewByRoles'
import { composeAltCode, IsHakIds, removeUndefined } from '../lib/helper'
import { useNavigateIfNotAdmin, useNavigateIfNotLogined } from '../lib/helprHooks'
import { Student, User } from '../lib/types'
import { useGetAllStudentsQuery, useGetCurrentUserQuery, usePostRoleMutation } from '../redux/reducer/api'

export const ManageRoles: React.FC = () => {
  useNavigateIfNotLogined()
  useNavigateIfNotAdmin()

  const navigate = useNavigate()
  const { data: user, isLoading: isLoadingCurrentUser, refetch: refetchCurrentUser, isError: isErrorCurrentUser } = useGetCurrentUserQuery()
  const { data: students, isLoading: isLoadingStudents, refetch: refetchAllStudents } = useGetAllStudentsQuery()

  const ref = useRef<{ reset: () => void }>(null)
  const [uids, setUids] = useState<string[]>([])
  const [selected, setSelected] = useState<Set<Student & User>>(new Set())

  const [hakIds, sethakIds] = useState<string>("")

  const [role, setRole] = useState<string>("")

  const [postRole] = usePostRoleMutation()

  const hakIdStudentMap = new Map<string, Student & User>() 
  students?.forEach(student => hakIdStudentMap.set(composeAltCode(student), student))
  
  useEffect(() => {
    if (isLoadingCurrentUser) return
    if (user === undefined || !user.roles.includes('admin') || isErrorCurrentUser) {
      navigate('/')
    }
  }, [user])

  useEffect(() => {
    refetchCurrentUser()
  }, [])

  return (
    <div>
      <Navbar active="manageroles" />
      <Container maxW='container.lg' px={4} py={4}>
        <VStack spacing={7} align="normal">
          <VStack spacing={3} align="normal">
            <chakra.h2 fontWeight="bold" fontSize="lg">
              역할 선택
            </chakra.h2>
            <Input value={role} onChange={e=> setRole(e.target.value)}/>

            <chakra.h2 fontWeight="bold" fontSize="lg">
              학생 선택
            </chakra.h2>
            <Skeleton isLoaded={!isLoadingCurrentUser && !isLoadingStudents}>
              <StudentDropdown
                students={(students || [])}
                selected={uids} setSelected={setUids}
                ref={ref}
              />
            </Skeleton>
            <chakra.h2 fontWeight="bold" fontSize="lg">
              데이터로 학생 선택
            </chakra.h2>
            <FormControl isInvalid={!IsHakIds(hakIds.split(' ')) && (hakIds !== "")}>
              <Input value={hakIds} onChange={(e) => sethakIds(e.target.value)}></Input>
            </FormControl>
            <Flex>
              <Spacer />
              <HStack spacing={3}>
                <Button
                  variant="solid" size="sm"
                  onClick={() => {
                    ref.current?.reset()
                    setRole("")
                    sethakIds("")
                  }}
                >
                  취소
                </Button>
                <Button
                  variant="solid" colorScheme="purple" size="sm"
                  disabled={(uids.length === 0 && hakIds === "") || ((hakIds !== "") && !IsHakIds(hakIds.split(' ')))}
                  onClick={async () => {
                    const uidsByHakId : string[] = []
                    hakIds.split(' ').forEach((hakId) => {
                      const student = hakIdStudentMap.get(hakId)
                      if (student === undefined) return
                      uidsByHakId.push(student.id)
                    })

                    //졸려서 대충짬 ㅈㅅ
                    await postRole({
                      id: role,
                      add: [],
                      del: [...uids, ...uidsByHakId]
                    })
                    refetchAllStudents()
                    sethakIds('')
                    setRole('')
                    ref.current?.reset()
                  }}
                >
                  역할제거
                </Button>
                <Button
                  variant="solid" colorScheme="purple" size="sm"
                  disabled={(uids.length === 0 && hakIds === "") || ((hakIds !== "") && !IsHakIds(hakIds.split(' ')))}
                  onClick={async () => {
                    const uidsByHakId : string[] = []
                    hakIds.split(' ').forEach((hakId) => {
                      const student = hakIdStudentMap.get(hakId)
                      if (student === undefined) return
                      uidsByHakId.push(student.id)
                    })

                    //졸려서 대충짬 ㅈㅅ
                    await postRole({
                      id: role,
                      add: [...uids, ...uidsByHakId],
                      del: []
                    })
                    refetchAllStudents()
                    sethakIds('')
                    setRole('')
                    ref.current?.reset()
                  }}
                >
                  역할부여
                </Button>
              </HStack>
            </Flex>
          </VStack>


          <VStack spacing={3} align="normal">
            <chakra.h2 fontWeight="bold" fontSize="lg">
              학생 목록
            </chakra.h2>
            <Skeleton isLoaded={!isLoadingCurrentUser && !isLoadingStudents}>
              <StudentViewByRoles
                students={removeUndefined(students || [])}
                select={{ selected, setSelected }}
              />
            </Skeleton>
          </VStack>

        </VStack>
      </Container>
    </div>
  )
}
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { GoogleLoginResponse } from "react-google-login";
import { sign } from "jsonwebtoken"

const JWT_SECRET = 'banana-mintchoco'

export interface CredentialState {
  user?: GoogleLoginResponse
  access_token?: string
}

const initialState: CredentialState = {}

export const credentialSlice = createSlice({
  name: 'credential',
  initialState,
  reducers: {
    setCredential: (state, action: PayloadAction<GoogleLoginResponse>) => {
      state.user = action.payload
      state.access_token = sign({ token: action.payload.tokenObj.access_token }, JWT_SECRET)
    },
    reloadCredential: (state, action: PayloadAction<GoogleLoginResponse["tokenObj"]>) => {
      if (state.user) state.user.tokenObj = action.payload
      state.access_token = sign({ token: action.payload.access_token }, JWT_SECRET)
    },
    resetCredential: (state) => {
      state.user = undefined
      state.access_token = undefined
    }
  }
})

export const { setCredential, reloadCredential, resetCredential } = credentialSlice.actions
